<template>
  <div :class="['tyre-search', forPage]">
    <div
      :class="[
        'tire-search-filter-container',
        'container',
        forPage
      ]"
    >
      <v-overlay
        v-if="loading"
        :z-index="20"
        :opacity="0.7"
        absolute
        color="#fff"
      >
        <v-progress-circular
          indeterminate
          color="accent"
        />
      </v-overlay>
      <div
        v-for="(selectFilter, index) in selectFilterFields"
        :key="`${selectFilter.key}${index}`"
        :style="{
          gridArea: `${selectFilter.key}`
        }"
        class="filter__item"
      >
        <button
          v-if="!isEnableSelectFilter[selectFilter.filterKey] && selectFilter.requiredValueBefore"
          class="enable-button-overlay"
          @click="onClickEnableButton(selectFilter.key, selectFilter.requiredValueBefore)"
        />
        <p class="label">
          {{ $t(selectFilter.singleSelectLabel) }}:
          <span v-if="selectFilter.required">
            *
          </span>
        </p>
        <base-select-filter-dropdown
          :ref="`${selectFilter.filterKey}FilterDropdown`"
          v-model="inputSelectFilter[selectFilter.filterKey]"
          :is-loading="isLoadingFilter"
          :option-items="selectFilterOptions[selectFilter.key]"
          :placeholder="selectFilter.placeholder"
          :single="selectFilter.single"
          :select-label="inputSelectFilter[selectFilter.filterKey] && inputSelectFilter[selectFilter.filterKey].length > 1 ? selectFilter.pluralSelectLabel : selectFilter.singleSelectLabel"
          :is-use-label-in-selected-value="selectFilter.isUseLabelInSelectedValue"
          :disabled="selectFilter.disabled"
          :is-search-submit="selectFilter.isSearchSubmit"
          :filter-key="selectFilter.filterKey"
          :sortable="selectFilter.sortable"
          :sort-function="selectFilter.sortFunction"
          :dropdown-class="showErrorSelectFilter[selectFilter.filterKey] ? 'show-error' : null"
          @input="onInputSelectFilter(selectFilter, `${selectFilter.filterKey}FilterDropdown`)"
        />
      </div>

      <div class="attribute-group">
        <base-checkbox
          v-for="(attribute, index) in attributeFilterFields"
          :key="`${attribute.filterValue}${index}`"
          v-model="inputAttributeFilterState[attribute.filterValue]"
          :style="{
            gridArea: attribute.area
          }"
          check-box-button
          @input="onFilterAttribute(attribute)"
        >
          <template #icon>
            <component
              :is="`icon-${attribute.icon}`"
              v-if="attribute.icon"
            />
            <span v-else>
              {{ attribute.value.includes('langkey') ? $t(attribute.value) : attribute.value }}
            </span>
          </template>
        </base-checkbox>
      </div>

      <div class="function">
        <v-btn
          outlined
          class="icon reset-filter__button"
          @click="onResetFilter"
        >
          {{ $t('langkey.clear') }}
          <icon-reset />
        </v-btn>
        <v-btn
          color="primary"
          class="icon search-filter__button"
          @click="onSearchFilter"
        >
          {{ $t('langkey.search') }}
          <icon-search color="var(--theme-white)" />
        </v-btn>
      </div>

      <div
        v-if="rangeFilterFields.length"
        class="range-filter__wrapper"
      >
        <div
          v-for="(range, index) in rangeFilterFields"
          :key="`${range.filterKey}${index}`"
          class="range__item"
        >
          <p
            v-if="range.icon"
            class="slider-label"
          >
            <component
              :is="`icon-${range.icon}`"
              class="icon"
            />
          </p>
          <vue-slider
            v-model="inputRangeFilter[range.filterKey]"
            :dot-size="10"
            :height="3"
            :data="mockRangeData[range.filterKey]"
            :data-value="'id'"
            :data-label="'label'"
            tooltip="none"
            :class="{
              'is-active': isInitialRangeFilter(range.filterKey)
            }"
            @change="onInputRangeFilter(range.filterKey)"
          />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="wrapper__tire-search-result">
        <div class="table__section">
          <div class="table-title__wrapper">
            <p class="table__title">
              {{ $t('langkey.tyre-result-list') }}
            </p>
            <div class="filter-wrapper">
              <div
                v-if="isAllowAddressUser && isTyreModule"
                class="delivery-address"
              >
                <span class="label">{{ $t('langkey.extended_delivery_address') }}:</span>
                <base-select-filter-dropdown
                  ref="selectDropdownDeliveryAddress"
                  v-model="selectedAddressOption"
                  is-search-delivery-address
                  :is-loading="isLoadingDeliveryAddresses"
                  :option-items="getUserAddressOptions"
                  :placeholder="$t('langkey.select')"
                  is-radio
                  @input="onSelectAddress"
                />
                <v-btn
                  color="primary"
                  @click="createNewAddress"
                >
                  {{ $t('langkey.adding_new_delivery_address') }}
                </v-btn>
              </div>
              <div class="express">
                <label class="label">
                  <v-tooltip
                    max-width="200"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                      >
                        <base-checkbox
                          v-model="inputFilterExpressState"
                          class="today"
                          check-box-button
                          @input="onFilterExpress"
                        >
                          <template #icon>
                            <icon-express-truck
                              style="position: relative; top: 2px;"
                              class="mr-2"
                            />
                            <span>
                              {{ $t('langkey.express_delivery') }}
                            </span>
                          </template>
                        </base-checkbox>
                      </div>
                    </template>
                    <span>
                      {{ $t('langkey.langkey.direct_delivery_tip') }}
                    </span>
                  </v-tooltip>
                </label>
              </div>
            </div>
          </div>
          <base-virtual-table
            :is-loading="loading || isLoadingAssign"
            :table-fields="tableField"
            :table-fields-supplier="tableFieldExpand"
            :table-items="filteredSliceTableItems"
            :show-empty="!isStart"
            is-tyre
            @select-row="onTableRowSelect"
            @on-sort="onTableSort"
          >
            <template #th(rollingResistance)>
              <icon-rolling-resistance />
            </template>
            <template #th(wetGrip)>
              <icon-wet-grip />
            </template>
            <template #th(noiseClass)>
              <icon-volume />
            </template>
            <template #th(prefix)="{ item }">
              <v-tooltip
                max-width="200"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="prefix-text"
                    v-on="on"
                  >
                    {{ $t(item.text) }}
                  </span>
                </template>
                <p>
                  {{ $t('langkey.truck_re_mouse_over') }}
                </p>
              </v-tooltip>
            </template>
            <template #th(pp)="{ item }">
              {{ $t(item.text) }} €
            </template>
            <template #td(profile)="{ item, value }">
              <div class="d-flex align-center">
                <button @click.prevent="onClickInfo($event, item)">
                  <icon-info size="12" />
                </button>
                <p class="ml-2">
                  {{ value }}
                  <template v-if="item.runFlat">
                    {{ ' ' + item.runFlat }}
                  </template>
                  <template v-if="item.strengthened">
                    {{ ' ' + item.strengthened }}
                  </template>
                  <template v-if="item.mudAndSnow === 'Y'">
                    {{ ' M+S' }}
                  </template>
                  <template v-if="item.threePeakMountainSnowFlake === 'Y'">
                    {{ ' 3PMSF' }}
                  </template>
                  <template v-if="item.oeIdentifier">
                    {{ ' ' + item.oeIdentifier }}
                  </template>
                </p>
              </div>
            </template>
            <template #td(size)="{ item }">
              {{ `${item.width}${item.aspectRatio && '/'}${item.aspectRatio} ${item.radial}${item.diameter}` }}
            </template>
            <template #td(season)="{ value }">
              <icon-summer
                v-if="value.toLowerCase() === 's'"
                color="#ffda2d"
                style="position: relative; top: 3px;"
              />
              <icon-winter
                v-else-if="value.toLowerCase() === 'w'"
                color="#4ecffb"
                style="position: relative; top: 3px;"
              />
              <icon-all-season
                v-else-if="value.toLowerCase() === 'a'"
                style="position: relative; top: 3px;"
                color
              />
            </template>
            <template #td(prefix)="{ value }">
              <v-tooltip
                max-width="200"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <icon-availability
                      v-if="value === 'RE'"
                      :available="value === 'RE'"
                    />
                  </div>
                </template>
                <p>
                  {{ $t('langkey.truck_re_mouse_over') }}
                </p>
              </v-tooltip>
            </template>
            <template #td(usage)="{ item }">
              <span v-if="item.usage">{{ $t(usageTranslationItems[item.usage.toLowerCase()]) }}</span>
              <span v-else>-</span>
            </template>
            <template #td(prices)="{ item }">
              {{ (item.prices['listPrice'] || 0) | price }}
            </template>
            <!-- <template #td(prices)="{ value }">
              {{ (value['listPrice'] || 0) | price }}
            </template>
            <template #td(pp)="{ item }">
              <template v-if="inputFilterExpressState && item.expressAvailable">
                {{ item.prices.directDelivery | price }}
              </template>
              <template v-else>
                {{ item.prices.bestPrice | price }}
              </template>
            </template> -->
            <template #td(discount)="{ item }">
              {{ item.prices['discount'].toFixed(2) + '%' }}
            </template>
            <template
              v-for="(priceTypeValue, tdIndex) in priceTypeValues"
              v-slot:[`td(${priceTypeValue})`]="{ item }"
            >
              <p
                v-if="inputFilterExpressState.tomorrow && item.expressAvailable"
                :key="`expressStatePrice${tdIndex}`"
              >
                <span v-if="!isOptIn">
                  {{ item.prices.directDelivery[priceTypeState] | price }}
                </span>
                <span v-else>
                  {{ item.prices.directDelivery[priceTypeValue] | price }}
                </span>
              </p>
              <p
                v-else
                :key="tdIndex"
              >
                <span v-if="!isOptIn">
                  {{ item.prices.bestPrice[priceTypeState] | price }}
                </span>
                <span v-else>
                  {{ item.prices.bestPrice[priceTypeValue] | price }}
                </span>
              </p>
            </template>
            <template #td(offer)="{ item }">
              <a
                class="offer-text"
                @click="toTyreOffer(item)"
              >{{ $t('langkey.offer') }}</a>
            </template>
            <template #td(expressAvailable)="{ value }">
              <icon-express-truck
                v-if="value"
                style="position:relative;top:2px;"
              />
            </template>
            <template #td(eligibilityValue)="{ value }">
              <span v-if="value">{{ `DE ${value * 0.8}%` }}</span>
            </template>
            <template #td(data-table-expand)>
              <v-btn
                icon
                plain
              >
                <icon-more />
              </v-btn>
            </template>
            <template #thSupplier(order)="{ item }">
              <v-btn
                icon
                plain
                @click="onCloseSupplier(item)"
              >
                <icon-close size="11" />
              </v-btn>
            </template>

            <template #tdSupplier(supplier)="{ value }">
              <router-link
                v-if="userType === 'TEST USER'"
                class="link__register-full"
                :to="{ name: 'register-full' }"
                rel="nofollow"
              >
                {{ $t('langkey.register_full') }}
              </router-link>
              <span v-else>{{ value }}</span>
            </template>
            <template #tdSupplier(stock)="{ value }">
              {{ value >= 20 ? '20+' : value }}
            </template>
            <template #tdSupplier(listPrice)="{ value }">
              {{ value | price }}
            </template>
            <template #tdSupplier(discount)="{ item }">
              <p v-if="inputFilterExpressState.tomorrow">
                {{ parseInt(item.listPrice) === 0
                  ? '0%'
                  : `${((1 - (parseFloat(item.directDeliveryPrice.purchasePrice) / parseFloat(item.listPrice))) * 100).toFixed(2)}%`
                }}
              </p>
              <p v-else>
                {{ parseInt(item.listPrice) === 0
                  ? '0%'
                  : `${((1 - (parseFloat(item.price.purchasePrice) / parseFloat(item.listPrice))) * 100).toFixed(2)}%`
                }}
              </p>
            </template>
            <template
              v-for="(priceTypeValue, tdSupplierIndex) in priceTypeValues"
              v-slot:[`tdSupplier(${priceTypeValue})`]="{ item }"
            >
              <!-- <p
                v-if="inputFilterExpressState.tomorrow"
                :key="tdSupplierIndex"
              > -->
              <p
                v-if="inputFilterExpressState"
                :key="`expressStateSupplier${tdSupplierIndex}`"
              >
                <span v-if="!isOptIn">
                  {{ item.directDeliveryPrice[priceTypeState] | price }}
                </span>
                <span v-else>
                  {{ item.directDeliveryPrice[priceTypeValue] | price }}
                </span>
              </p>
              <p
                v-else
                :key="tdSupplierIndex"
              >
                <span v-if="!isOptIn">
                  {{ item.price[priceTypeState] | price }}
                </span>
                <span v-else>
                  {{ item.price[priceTypeValue] | price }}
                </span>
              </p>
            </template>
            <!-- <template #tdSupplier(purchasePrice)="{ item, value }">
              <template v-if="inputFilterExpressState">
                {{ item.directDeliveryPurchasePrice | price }}
              </template>
              <template v-else>
                {{ value | price }}
              </template>
            </template> -->
            <template #tdSupplier(expressShipmentAvailable)="{ item }">
              <v-tooltip
                v-if="item.directDeliveryNoteDay || inputFilterExpressState"
                max-width="400"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <icon-express-truck style="position: relative; top: 2px;" />
                  </div>
                </template>
                <span>
                  {{ tyreSuppliersDeliveryTooptip[`${item.tyreTag}_${item.id}`] }}
                </span>
              </v-tooltip>
            </template>
            <template #tdSupplier(eligibilityValue)="{ value }">
              <span v-if="value">{{ `DE ${value * 0.8}%` }}</span>
            </template>
            <template #tdSupplier(qty)="{ item }">
              <div class="qty-container">
                <v-btn
                  :disabled="selectedTyreInSupplierQuantityState[item.tyreTag][item.id] < 2"
                  icon
                  plain
                  @click="onClickSubstactQuantity(item)"
                >
                  <icon-subtract />
                </v-btn>
                <input
                  v-model="selectedTyreInSupplierQuantityState[item.tyreTag][item.id]"
                  class="qty__input"
                  type="number"
                  @change="onChangeQuantity($event, item)"
                >
                <v-btn
                  :disabled="selectedTyreInSupplierQuantityState[item.tyreTag][item.id] >= item.stock"
                  icon
                  plain
                  @click="onClickAddQuantity(item)"
                >
                  <icon-add />
                </v-btn>
              </div>
            </template>
            <template #tdSupplier(order)="{ item }">
              <v-btn
                icon
                plain
                @click="onClickAddToCart(item)"
              >
                <icon-add-to-cart color="var(--theme-gray-3)" />
              </v-btn>
            </template>
          </base-virtual-table>
        </div>
        <div class="column__news-room">
          <news-room is-tyre-search />
        </div>
      </div>
    </div>

    <tyre-search-detail-dialog
      ref="tyreSearchDetailDialogRef"
      :tyre-data="tyreDetailDialogItem"
      :for-page="forPage"
      :is-motorbike="isMotorbike"
      :tyre-image-item="tyreImageItem"
    />

    <v-dialog
      v-model="showAddToCartDialog"
      max-width="550"
    >
      <v-card class="dialog tyre-search">
        <v-card-title>
          <h5 class="text">
            {{ $t('langkey.cart-dialog-head') }}
          </h5>
          <p class="description">
            {{ $t('langkey.cart-dialog-description') }}
          </p>
        </v-card-title>
        <v-card-actions>
          <v-btn
            class="icon"
            outlined
            plain
            @click="onContinueShopping"
          >
            {{ $t('langkey.cart-dialog-continue') }} <icon-add-to-cart />
          </v-btn>
          <v-btn
            color="primary"
            class="icon"
            @click="onShowCart"
          >
            {{ $t('langkey.cart-dialog-confirm') }} <icon-cart-show color="var(--theme-white)" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showCartDialogConfiguratorTyre"
      max-width="1000"
    >
      <v-card class="dialog wheel-detail">
        <v-card-title>
          <h5 class="text">
            {{ $t('langkey.cart-dialog-head') }}
          </h5>
        </v-card-title>
        <v-card-text>
          <v-data-table
            class="primary-style select-wheel-table"
            :headers="tableFieldSelectedItem"
            :items="selectedItems.displayCartItems"
            hide-default-footer
            disable-sort
          >
            <template
              v-for="(field, index) in tableFieldSelectedItem"
              v-slot:[`header.${field.value}`]="{ header }"
            >
              <span :key="`${field.value}${index}`">{{ $t(header.text) }}</span>
            </template>
            <template v-slot:[`item.image`]="{ item }">
              <img
                v-if="item.isWheel"
                v-show="item.picturePerspective"
                v-lazy="getWheelImageBySize(item.picturePerspective)"
                class="cart-img"
              >
              <img
                v-else-if="item.isTpms"
                v-show="item.imageTemplate"
                v-lazy="getTpmsImageBySize(item.imageTemplate)"
                class="cart-img"
              >
              <img
                v-else-if="item.isTyre"
                v-show="item.imageTemplate"
                v-lazy="getTyreImageBySize(item.imageTemplate, 120, 120)"
                class="cart-img"
              >
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <div v-if="item.isWheel">
                <div
                  v-if="item.isCombination"
                  class="combination"
                >
                  <div>{{ item.F.description }}</div>
                  <div class="mt-3">
                    {{ item.R.description }}
                  </div>
                </div>
                <span v-else>{{ item.description }}</span>
              </div>
              <div v-else>
                {{ item.description }}
              </div>
            </template>
            <template v-slot:[`item.articleId`]="{ item }">
              <div v-if="item.isWheel">
                <div
                  v-if="item.isCombination"
                  class="combination"
                >
                  <div>{{ item.F.man }}</div>
                  <div class="mt-3">
                    {{ item.R.man }}
                  </div>
                </div>
                <span v-else>{{ item.man }}</span>
              </div>
              <div v-else>
                {{ item.articleNumber }}
              </div>
            </template>
            <template v-slot:[`item.quantity`]="{ item, index }">
              <div class="qty__wrapper">
                <div v-if="item.isWheel">
                  <v-btn
                    :disabled="selectedItems.wheel.quantity < 2"
                    icon
                    plain
                    @click="onSubtractQuantitySelectedWheel"
                  >
                    <icon-subtract />
                  </v-btn>
                  <input
                    v-model.number="selectedItems.wheel.quantity"
                    class="qty__input"
                    @change="onUpdateQuantitySelectedWheel($event, index)"
                  >
                  <v-btn
                    :disabled="selectedItems.wheel.quantity >= item.stock"
                    icon
                    plain
                    @click="onAddQuantitySelectedWheel"
                  >
                    <icon-add />
                  </v-btn>
                </div>
                <div v-else-if="item.isTpms">
                  <v-btn
                    :disabled="selectedItems.tpms.quantity < 2"
                    icon
                    plain
                    @click="onSubtractQuantitySelectedTpms"
                  >
                    <icon-subtract />
                  </v-btn>
                  <input
                    v-model.number="selectedItems.tpms.quantity"
                    class="qty__input"
                    @change="onUpdateQuantitySelectedTpms($event, index)"
                  >
                  <v-btn
                    :disabled="selectedItems.tpms.quantity >= item.stock"
                    icon
                    plain
                    @click="onAddQuantitySelectedTpms"
                  >
                    <icon-add />
                  </v-btn>
                </div>
                <div v-else-if="item.isTyre">
                  <v-btn
                    :disabled="selectedItems.tyre.quantity < 2"
                    icon
                    plain
                    @click="onSubtractQuantitySelectedTyre"
                  >
                    <icon-subtract />
                  </v-btn>
                  <input
                    v-model.number="selectedItems.tyre.quantity"
                    class="qty__input"
                    @change="onUpdateQuantitySelectedTyre($event, index)"
                  >
                  <v-btn
                    :disabled="selectedItems.tyre.quantity >= item.stock"
                    icon
                    plain
                    @click="onAddQuantitySelectedTyre"
                  >
                    <icon-add />
                  </v-btn>
                </div>
              </div>
            </template>
            <template v-slot:[`item.total`]="{ item }">
              <span v-if="item.isWheel">
                {{ item.prices.bestPrice * selectedItems.wheel.quantity | price }}
              </span>
              <span v-else-if="item.isTpms">
                {{ item.purchasePrice * selectedItems.tpms.quantity | price }}
              </span>
              <span v-else-if="item.isTyre">
                {{ item.purchasePrice * selectedItems.tyre.quantity | price }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="icon"
            color="primary"
            @click="onContinueShoppingConfiguratorTyre"
          >
            {{ $t('langkey.cart-dialog-continue') }} <icon-arrow color="var(--theme-white)" />
          </v-btn>
          <v-btn
            class="icon"
            outlined
            plain
            @click="onShowCartConfiguratorTyre"
          >
            {{ $t('langkey.cart-dialog-confirm') }} <icon-cart-show color="var(--theme-black)" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      key="registeredDialog"
      v-model="registeredDialog"
      max-width="330"
    >
      <v-card>
        <v-card-title />
        <v-card-text>
          <div v-html="$t('langkey.demo-add-to-cart')" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="registeredDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="createNewAddressDialog"
      max-width="652"
    >
      <v-card class="dialog create-new-address">
        <v-card-title>
          <h5 class="text">
            {{ $t('langkey.add_delivery_address') }}
          </h5>
          <v-btn
            icon
            plain
            @click="createNewAddressDialog = false"
          >
            <icon-close size="18" />
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_address_name') }} :
            </p>
            <v-text-field
              v-model="inputAddressData.name.value"
              :error-messages="[errorMessageInputAddressData.name]"
              :label="`${$t('langkey.enter-name')}`"
              solo
              @input="errorMessageInputAddressData.name = ''"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_address_street') }} :
            </p>
            <v-text-field
              v-model="inputAddressData.street.value"
              :error-messages="[errorMessageInputAddressData.street]"
              :label="`${$t('langkey.enter-street-name')}`"
              solo
              @input="errorMessageInputAddressData.street = ''"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_address_number') }} :
            </p>
            <v-text-field
              v-model="inputAddressData.streetNumber.value"
              :error-messages="[errorMessageInputAddressData.streetNumber]"
              :label="`${$t('langkey.enter-street-number')}`"
              solo
              @input="errorMessageInputAddressData.streetNumber = ''"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_adress_code') }} :
            </p>
            <v-text-field
              v-model="inputAddressData.zip.value"
              :error-messages="[errorMessageInputAddressData.zip]"
              type="number"
              :label="`${$t('langkey.enter-zip')}`"
              solo
              @input="errorMessageInputAddressData.zip = ''"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_adress_city') }} :
            </p>
            <v-text-field
              v-model="inputAddressData.city.value"
              :error-messages="[errorMessageInputAddressData.city]"
              :label="`${$t('langkey.enter-city-name')}`"
              solo
              @input="errorMessageInputAddressData.city = ''"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_adress_country') }} :
            </p>
            <v-select
              v-model="inputAddressData.countryCode.value"
              :error-messages="[errorMessageInputAddressData.countryCode]"
              :items="['DE']"
              item-text="label"
              item-value="value"
              solo
              @input="errorMessageInputAddressData.countryCode = ''"
            >
              <template #append>
                <icon-caret />
              </template>
            </v-select>
          </div>
          <div style="display: flex">
            <base-checkbox
              :value="inputAddressData.defaultValue.value"
              :label-text="$t('langkey.new_standard_delivery_address')"
              reverse
              @input="toggleDefaultValue"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            plain
            @click="createNewAddressDialog = false"
          >
            {{ $t('langkey.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            @click="onSaveAddress"
          >
            {{ $t('langkey.save-address') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
@import '@/styles/components/tyre-search.scss';
</style>

<script>
import IconReset from '@/components/icons/IconReset';
import IconInfo from '@/components/icons/IconInfo';
import IconRollingResistance from '@/components/icons/IconRollingResistance';
import IconWetGrip from '@/components/icons/IconWetGrip';
import IconVolume from '@/components/icons/IconVolume';
import IconSummer from '@/components/icons/IconSummer';
import IconWinter from '@/components/icons/IconWinter';
import IconAllSeason from '@/components/icons/IconAllSeason';
import IconTruckSteer from '@/components/icons/IconTruckSteer';
import IconRoad from '@/components/icons/IconRoad';
import IconTruckTrailer from '@/components/icons/IconTruckTrailer';
import IconTrolley from '@/components/icons/IconTrolley';
import IconStock from '@/components/icons/IconStock';
import IconMore from '@/components/icons/IconMore';
import IconSubtract from '@/components/icons/IconSubtract';
import IconAdd from '@/components/icons/IconAdd';
import IconAvailability from '@/components/icons/IconAvailability';
import IconCart from '@/components/icons/IconCart';
import IconCartShow from '@/components/icons/IconCartShow';
import IconExpressTruck from '@/components/icons/IconExpressTruck';
import IconTpms from '@/components/icons/IconTpms';
import IconTruckDrive from '@/components/icons/IconTruckDrive';
import IconCar from '@/components/icons/IconCar';
import IconSuv from '@/components/icons/IconSuv';
import IconVan from '@/components/icons/IconVan';
import IconCaret from '@/components/icons/IconCaret';
import IconArrow from '@/components/icons/IconArrow';

import NewsRoom from '@/components/NewsRoom';
import TyreSearchDetailDialog from '@/components/TyreSearchDetailDialog';

import { priceTypeItems } from '@/constants/priceTypes';
import { usageTranslationItems } from '@/constants/usageTranslation';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

import dayjs from 'dayjs';

import { mapState, mapGetters } from 'vuex';
import { isObjectEmpty } from '@/utils';
import { getImageBySize } from '@/utils/index.js';

import axios from 'axios';
import { BASE_API_URL } from '@/services';
import { TyreService, CartService } from '@/services';

const insertItemInArrayAtIndex = (arr, index, item) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted items
  ...item,
  // part of the array after the specified index
  ...arr.slice(index),
];

export default {
  components: {
    IconReset,
    IconInfo,
    IconRollingResistance,
    IconWetGrip,
    IconVolume,
    IconSummer,
    IconWinter,
    IconAllSeason,
    IconTruckSteer,
    IconRoad,
    IconTruckTrailer,
    IconTrolley,
    IconStock,
    IconMore,
    IconSubtract,
    IconAdd,
    IconAvailability,
    IconCart,
    IconCartShow,
    IconExpressTruck,
    IconTpms,
    IconTruckDrive,
    IconCar,
    IconSuv,
    IconVan,
    IconCaret,
    IconArrow,

    NewsRoom,
    TyreSearchDetailDialog,
    VueSlider,
  },
  props: {
    searchParameters: {
      type: Array,
      default: () => [],
    },
    searchParametersSecond: {
      type: Array,
      default: () => [],
    },
    tableField: {
      type: Array,
      default: () => [],
    },
    tableFieldExpand: {
      type: Array,
      default: () => [],
    },
    tableFieldSelectedItem: {
      type: Array,
      default: () => [],
    },
    tableItems: {
      type: Array,
      default: () => [],
    },
    isSuppliersHidden: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isStart: {
      type: Boolean,
      default: false,
    },
    selectFilterFields: {
      type: Array,
      default: () => [],
    },
    selectFilterOptions: {
      type: Object,
      default: () => {},
    },
    attributeFilterFields: {
      type: Array,
      default: () => [],
    },
    rangeFilterFields: {
      type: Array,
      default: () => [],
    },
    isLoadingPagination: {
      type: Boolean,
      default: false,
    },
    isNoMorePaginationResult: {
      type: Boolean,
      default: false,
    },
    isLoadingFilter: {
      type: Boolean,
      default: false,
    },
    clearAndSearch: {
      type: Boolean,
      default: false,
    },
    forPage: {
      type: String,
      default: '',
    },
    isMotorbike: {
      type: Boolean,
      default: false,
    },
    isCar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',

      inputAttributeFilterState: {},
      inputAttributeFilterValue: {},
      inputRangeFilter: {
        rollingResistances: null,
        wetGrips: null,
        noiseClasses: null,
      },
      inputRangeFilterValue: {},
      inputSelectFilter: {},
      inputFilterExpressState: false,
      inputFilterExpress: {},
      inputFilterMarketplaceAddress: {},
      isErrorSelectFilter: false,
      showErrorSelectFilter: {},

      mockRangeData: {
        rollingResistances: [
          {
            label: 'A',
            id: 'A',
          },
          {
            label: 'B',
            id: 'B',
          },
          {
            label: 'C',
            id: 'C',
          },
          {
            label: 'D',
            id: 'D',
          },
          {
            label: 'E',
            id: 'E',
          },
          {
            label: 'F',
            id: 'F',
          },
          {
            label: 'G',
            id: 'G',
          },
        ],
        wetGrips: [
          {
            label: 'A',
            id: 'A',
          },
          {
            label: 'B',
            id: 'B',
          },
          {
            label: 'C',
            id: 'C',
          },
          {
            label: 'D',
            id: 'D',
          },
          {
            label: 'E',
            id: 'E',
          },
          {
            label: 'F',
            id: 'F',
          },
          {
            label: 'G',
            id: 'G',
          },
        ],
        noiseClasses: [
          {
            label: '1',
            id: 'A',
          },
          {
            label: '2',
            id: 'B',
          },
          {
            label: '3',
            id: 'C',
          },
        ],
      },

      isLoadingAssign: false,
      sliceTableItems: [],

      tyreSuppliersDeliveryTooptip: {},
      selectedTyreInSupplierQuantityState: {},
      showAddToCartDialog: false,

      selectedAddToCartItem: {},
      tableScrollEl: null,

      pagination: 1,

      debounceRangeFilter: null,

      isEnableSelectFilter: {},
      isMatchcodeSearch: false,
      isSetMatchcodeSearchFirstTime: true,
      matchCodeValue: '',

      tyreDetailDialogItem: {},
      registeredDialog: false,
      tyreImageItem: {},

      isEanSearch: false,

      isLoadingDeliveryAddresses: false,
      selectedAddressOption: [],
      createNewAddressDialog: false,
      inputAddressData: {
        name: {
          required: true,
          value: '',
        },
        street: {
          required: true,
          value: '',
        },
        streetNumber: {
          required: true,
          value: '',
        },
        zip: {
          required: true,
          value: '',
        },
        city: {
          required: true,
          value: '',
        },
        countryCode: {
          required: false,
          value: 'DE',
        },
        defaultValue: {
          required: false,
          value: false,
        },
      },
      errorMessageInputAddressData: {
        name: '',
        street: '',
        streetNumber: '',
        zip: '',
        city: '',
        countryCode: '',
      },
      priceTypeItems: priceTypeItems,
      usageTranslationItems: usageTranslationItems,
      showCartDialogConfiguratorTyre: false,
      selectedItems: {
        wheel: {
          isCombination: false,
          assignIds: [],
          quantity: 0,
          cartItems: [],
        },
        tpms: {
          assignId: 0,
          quantity: 0,
          cartItems: [],
        },
        tyre: {
          assignId: 0,
          quantity: 0,
          cartItems: [],
        },
        displayCartItems: [],
      },

      selectFilterKey: '',
    };
  },
  computed: {
    ...mapState({
      tyreAssignByTyreTag: (state) => state.tyre.tyreAssignByTyreTag,
      userType: (state) => state.user.userType,
      userAddresses: (state) => state.user.userAddresses,
      isAllowAddressUser: (state) => state.user.isAllowAddressUser,
      customerInformation: (state) => state.registration.customerInformation,
      priceTypeState: (state) => state.pricing.priceType,
      isOptIn: (state) => state.pricing.isOptIn,
      selectedConfiguratorItems: (state) => state.rimconfigCarConfigurator.selectedConfiguratorItems,
      allowChangeAddressForSource: (state) => state.cart.allowChangeAddressForSource,
    }),
    ...mapGetters({
      getUserAddressOptions: 'user/getUserAddressOptions',
      getCartItems: 'cart/getCartItems',
    }),
    getSelectFilterRequiredFields() {
      return this.selectFilterFields.filter((item) => item.required);
    },
    isRegistered() {
      return this.customerInformation.businessType &&
        this.customerInformation.contactPersons[0].fax &&
        this.customerInformation.bankAccountHolder &&
        this.customerInformation.bankName &&
        this.customerInformation.bankIban &&
        this.customerInformation.bankBic &&
        this.customerInformation.bankSepaAuthorized;
    },
    initFilterTyres() {
      if (isObjectEmpty(this.$route.query)) {
        return {};
      }
      return this.$route.query;
    },
    filteredSliceTableItems() {
      // if (this.inputFilterExpressState.today) {
      //   const getSupplierSources = this.sliceTableItems.slice().filter((item) => item.type === 'SupplierSource');
      //   const filteredTodayItems = getSupplierSources.filter((item) =>
      //     item.directDeliveryNoteDay === 'today'
      //     && dayjs().isBefore(dayjs(`${dayjs().format('YYYY-MM-DD')}T${item.directDeliveryNoteCutOfTime}:00.000Z`))
      //     && item.directDeliveryPurchasePrice,
      //   );
      //   if (filteredTodayItems.length === 0) {
      //     return this.sliceTableItems.slice().filter((item) => item.type !== 'SupplierSource');
      //   }
      //   const getSupplierIds = [...new Set(filteredTodayItems.map((item) => item.id))];
      //   return this.sliceTableItems.slice().filter((item) => {
      //     if (item.type === 'SupplierSource') {
      //       return getSupplierIds.includes(item.id);
      //     } else {
      //       return true;
      //     }
      //   });
      // }

      if (this.inputFilterExpressState) {
        const getSupplierSources = this.sliceTableItems.slice().filter((item) => item.type === 'SupplierSource');
        const filteredTodayItems = getSupplierSources.filter((item) =>
          (item.directDeliveryNoteDay === 'today' || item.directDeliveryNoteDay === 'tomorrow')
          && item.directDeliveryPrice.purchasePrice,
        );
        if (filteredTodayItems.length === 0) {
          return this.sliceTableItems.slice().filter((item) => item.type !== 'SupplierSource');
        }
        const getSupplierIds = [...new Set(filteredTodayItems.map((item) => item.id))];
        return this.sliceTableItems.slice().filter((item) => {
          if (item.type === 'SupplierSource') {
            return getSupplierIds.includes(item.id);
          } else {
            return true;
          }
        });
      }

      return this.sliceTableItems;
    },
    hasErrorInputAddress() {
      return Object.values(this.errorMessageInputAddressData).some((val) => val !== '');
    },
    priceTypeValues() {
      return priceTypeItems.map((item) => item.value);
    },
    isConfiguratorTyre() {
      return this.$route.name === 'rims-config-configurator_carTag-tyre';
    },
    selectedAddress() {
      return this.userAddresses.find((address) => {
        return `${address.name} ${address.street}, ${address.zip} ${address.city}, ${address.countryCode}` === this.selectedAddressOption[0];
      });
    },
    isTyreModule() {
      return this.$route.name === 'tyre-car' ||
        this.$route.name === 'tyre-truck' ||
        this.$route.name === 'tyre-motorbike' ||
        this.$route.name === 'tyre-industrial' ||
        this.$route.name === 'tyre-agriculture' ||
        this.$route.name === 'tyre-earthMover';
    },
  },
  watch: {
    tableItems(val) {
      this.sliceTableItems = val;
      if (this.isEanSearch) {
        if (val.length !== 0) {
          this.inputSelectFilter = {
            ...this.inputSelectFilter,
            widths: [this.sliceTableItems[0].width],
            aspectRatios: [this.sliceTableItems[0].aspectRatio],
            diameters: [this.sliceTableItems[0].diameter],
            manufacturers: [this.sliceTableItems[0].manufacturer],
          };
        }
      } else if (this.isMatchcodeSearch
        && this.isSetMatchcodeSearchFirstTime
        && this.matchCodeValue
        && this.matchCodeValue.length > 9
      ) {
        const manufacturersFromTable = val.slice()
          .map((item) => item.manufacturer);
        const uniqueManufacturers = [...new Set(manufacturersFromTable)];
        const removeUndefinedValues = uniqueManufacturers.filter((val) => val);

        if (removeUndefinedValues.length === 1) {
          this.inputSelectFilter = {
            ...this.inputSelectFilter,
            manufacturers: removeUndefinedValues,
          };
        }
        this.emitFilterInput();
        this.isSetMatchcodeSearchFirstTime = false;
      }
      setTimeout(() => {
        // if (this.sliceTableItems.length === 0 && this.inputFilterExpressState.today) {
        //   this.$store.dispatch('dialog/setShowErrorDialog', {
        //     status: true,
        //     title: this.$t('langkey.error-direct-delivery-subject'),
        //     message: this.$t('langkey.no-express-supplier-available'),
        //   });
        //   this.inputFilterExpress = {};
        //   this.inputFilterExpressState = {
        //     today: false,
        //     tomorrow: false,
        //   };
        //   this.emitFilterInput();
        //   this.onSearchFilter();
        // } else
        if (this.sliceTableItems.length === 0 && this.inputFilterExpressState) {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.error-direct-delivery-tomorrow-subject'),
            message: this.$t('langkey.no-express-supplier-available-tomorrow'),
          });
          this.inputFilterExpress = {};
          this.inputFilterExpressState = false;
          this.emitFilterInput();
          this.onSearchFilter();
        }
      }, 100);
    },
    matchCodeValue() {
      if (this.tableItems.length === 0) {
        return;
      }
      if (this.isEanSearch) {
        let filterAspectRatio;
        if (this.sliceTableItems[0].aspectRatio === '') {
          filterAspectRatio = [' '];
        } else {
          filterAspectRatio = [this.sliceTableItems[0].aspectRatio];
        }
        this.inputSelectFilter = {
          ...this.inputSelectFilter,
          widths: [this.sliceTableItems[0].width],
          aspectRatios: filterAspectRatio,
          diameters: [this.sliceTableItems[0].diameter],
          manufacturers: [this.sliceTableItems[0].manufacturer],
        };
        this.setEnableSelectFilter();
      } else if (this.isMatchcodeSearch && this.isSetMatchcodeSearchFirstTime && this.matchCodeValue.length > 9) {
        const manufacturersFromTable = this.tableItems.slice()
          .map((item) => item.manufacturer);
        const uniqueManufacturers = [...new Set(manufacturersFromTable)];
        const removeUndefinedValues = uniqueManufacturers.filter((val) => val);

        if (removeUndefinedValues.length === 1) {
          this.inputSelectFilter = {
            ...this.inputSelectFilter,
            manufacturers: removeUndefinedValues,
          };
        }
        this.emitFilterInput();
        this.isSetMatchcodeSearchFirstTime = false;
      } else if (this.isMatchcodeSearch && this.matchCodeValue.length < 9) {
        this.inputSelectFilter = {
          ...this.inputSelectFilter,
          manufacturers: [],
        };
        this.emitFilterInput();
      }
    },
    selectFilterOptions(val) {
      // If the chosen width has only one aspectRatio or diameter, select the aspectRatio or diameter
      if (this.selectFilterKey === 'widths') {
        this.selectFilterKey = '';
        if (val.aspectRatio.length === 1 || val.diameter.length === 1) {
          if (val.aspectRatio.length === 1) {
            this.inputSelectFilter.aspectRatios = val.aspectRatio.map((item) => item.value);
          }
          if (val.diameter.length === 1) {
            this.inputSelectFilter.diameters = val.diameter.map((item) => item.value);
          }
          this.setEnableSelectFilter();
          this.emitFilterInput();
        }
      }
    },
    $route() {
      this.matchCodeValue = this.$route.query.search;
      if (
        parseInt(this.matchCodeValue).toString().length >= 13 ||
        /([SWAswa])?[0-9][0-9][0-9][0-9][0-9][0-9][0-9]/.test(this.matchCodeValue)
      ) {
        this.setInitialInputSelectFilter();
        this.setInitialRange();
        this.setInitialAttribute();
        this.setEnableSelectFilter();
        this.setInitialFilterFromSearch();
      }
    },
  },
  created() {
    this.setInitialInputSelectFilter();
    this.setInitialRange();
    this.setInitialAttribute();
    this.setEnableSelectFilter();
    this.sliceTableItems = this.tableItems;
    this.$store.dispatch('registration/getCustomerInformation');
    if (this.isTyreModule) {
      this.getUserAddresses();
    }
  },
  mounted() {
    this.tableScrollEl = document.querySelector('.v-virtual-scroll');
    this.tableScrollEl.addEventListener('scroll', this.handleTableScroll);

    this.setInitialFilterFromSearch();

    if (this.isConfiguratorTyre) {
      this.selectedItems.wheel.quantity = this.selectedConfiguratorItems.wheel.quantity;
      this.selectedItems.wheel.isCombination = this.selectedConfiguratorItems.wheel.isCombination;
      this.selectedItems.wheel.assignIds = [...this.selectedConfiguratorItems.wheel.assignIds];
      this.selectedItems.wheel.cartItems = [...this.selectedConfiguratorItems.wheel.cartItems];

      if (
        this.selectedConfiguratorItems.tpms &&
        this.selectedConfiguratorItems.tpms.quantity &&
        this.selectedConfiguratorItems.tpms.assignId &&
        this.selectedConfiguratorItems.tpms.cartItems.length
      ) {
        this.selectedItems.tpms.quantity = this.selectedConfiguratorItems.tpms.quantity;
        this.selectedItems.tpms.assignId = this.selectedConfiguratorItems.tpms.assignId;
        this.selectedItems.tpms.cartItems = [...this.selectedConfiguratorItems.tpms.cartItems];
      }
    }
  },
  beforeDestroy() {
    this.tableScrollEl.removeEventListener('scroll', this.handleTableScroll);
  },
  methods: {
    setInitialConfiguratorTyreFilter() {
      const firstTyreSize = this.selectFilterOptions.tyreSize[0] && this.selectFilterOptions.tyreSize[0].value;
      if (!firstTyreSize) {
        return;
      }
      this.inputSelectFilter = {
        ...this.inputSelectFilter,
        tyreSizes: [firstTyreSize],
      };
      this.setEnableSelectFilter();
      this.emitFilterInput({
        initialSearch: true,
      });
      this.onSearchFilter();
    },
    setInitialFilterFromSearch() {
      this.matchCodeValue = this.$route.query.search;
      // check is EAN search
      if (parseInt(this.matchCodeValue).toString().length >= 13) {
        this.isEanSearch = true;
        return;
      }
      this.isEanSearch = false;
      if (/([SWAswa])?[0-9][0-9][0-9][0-9][0-9][0-9][0-9]/.test(this.matchCodeValue)) {
        const seasonFilterValue = this.matchCodeValue.charAt(0).toUpperCase();
        const hasSeasonFilter = ['S','W','A','G'].includes(seasonFilterValue);
        this.isMatchcodeSearch = true;
        this.isSetMatchcodeSearchFirstTime = true;
        const initFilterTyreSeasons = this.initFilterTyres.seasons;
        if (hasSeasonFilter) {
          let adjustedSeasonFilterValue = seasonFilterValue;
          if (seasonFilterValue === 'G') {
            adjustedSeasonFilterValue = 'A';
          }
          const diameterString = parseInt(this.matchCodeValue.substring(6, 9)).toString(); // have to remove space in case matchcode = 2055016 mich
          this.inputSelectFilter = {
            ...this.inputSelectFilter,
            seasons: [adjustedSeasonFilterValue],
            widths: [this.matchCodeValue.substring(1, 4)],
            aspectRatios: this.matchCodeValue.substring(4, 6) ? [this.matchCodeValue.substring(4, 6)] : [],
            diameters: diameterString.length > 2 // check if has floatin point
              ? [parseInt(diameterString) * 0.1]
              : this.matchCodeValue.substring(6, 8)
                ? [this.matchCodeValue.substring(6, 8)]
                : [],
          };
          this.inputAttributeFilterState = {
            ...this.inputAttributeFilterState,
            S: false,
            W: false,
            A: false,
            [adjustedSeasonFilterValue]: true,
          };
          this.inputAttributeFilterValue = {
            ...this.inputAttributeFilterValue,
            seasons: [adjustedSeasonFilterValue],
          };
        } else {
          const diameterString = parseInt(this.matchCodeValue.substring(5, 8)).toString(); // have to remove space in case matchcode = 2055016 mich
          this.inputSelectFilter = {
            ...this.inputSelectFilter,
            widths: [this.matchCodeValue.substring(0, 3)],
            aspectRatios: this.matchCodeValue.substring(3, 5) ? [this.matchCodeValue.substring(3, 5)] : [],
            diameters: diameterString.length > 2
              ? [parseInt(diameterString) * 0.1]
              : this.matchCodeValue.substring(5, 7)
                ? [this.matchCodeValue.substring(5, 7)]
                : [],
          };
          this.inputAttributeFilterState = {
            ...this.inputAttributeFilterState,
            S: false,
            W: false,
            A: false,
          };
          this.inputAttributeFilterValue = {
            ...this.inputAttributeFilterValue,
            seasons: [],
          };
        }

        if (initFilterTyreSeasons) {
          const seasons = initFilterTyreSeasons.split(',');
          this.inputSelectFilter = {
            ...this.inputSelectFilter,
            seasons: [...seasons],
          };
          this.inputAttributeFilterValue = {
            ...this.inputAttributeFilterValue,
            seasons: [...seasons],
          };
          seasons.forEach((season) => {
            this.inputAttributeFilterState = {
              ...this.inputAttributeFilterState,
              [season]: true,
            };
          });
        }
        this.setEnableSelectFilter();
        this.emitFilterInput({
          initialSearch: true,
        });
        this.inputFilterExpressState = false;
      }
    },
    setInitialInputSelectFilter() {
      this.selectFilterFields.forEach((item) => {
        if (item.filterKey !== 'eligibilityValue') {
          this.inputSelectFilter = {
            ...this.inputSelectFilter,
            [item.filterKey]: [],
          };
        }
      });

      this.inputSelectFilter = {
        ...this.inputSelectFilter,
        seasons: [],
        usages: [],
      };
    },
    setInitialRange() {
      Object.keys(this.mockRangeData).forEach((key) => {
        const value = this.mockRangeData[key];
        this.inputRangeFilter = {
          ...this.inputRangeFilter,
          [key]: [
            value[0].id,
            value[value.length - 1].id,
          ],
        };
      });
      this.inputRangeFilterValue = {};
    },
    setInitialAttribute() {
      if (this.$route.name === 'tyre-car' && this.$route.query.search) {
        this.attributeFilterFields.forEach((field) => {
          this.inputAttributeFilterState = {
            ...this.inputAttributeFilterState,
            [field.filterValue]: false,
          };
        });
        this.inputAttributeFilterValue = {};
      } else if (this.$route.name === 'tyre-car') {
        this.attributeFilterFields.forEach((field) => {
          this.inputAttributeFilterState = {
            ...this.inputAttributeFilterState,
            [field.filterValue]: false,
          };
        });
        this.inputAttributeFilterValue = {};
        this.inputAttributeFilterState = {
          car: true,
        };
        this.inputAttributeFilterValue = {
          vehicleTypes: 'car',
        };
      } else {
        this.attributeFilterFields.forEach((field) => {
          this.inputAttributeFilterState = {
            ...this.inputAttributeFilterState,
            [field.filterValue]: false,
          };
        });
        this.inputAttributeFilterValue = {};
      }
    },
    setEnableSelectFilter() {
      this.selectFilterFields.forEach((item) => {
        this.isEnableSelectFilter = {
          ...this.isEnableSelectFilter,
          [item.filterKey]: item.requiredValueBefore !== undefined
            ? this.hasSelectInputFilter(item.requiredValueBefore)
            : true,
        };
      });
    },
    onInputRangeFilter(key) {
      const value = this.inputRangeFilter[key];
      const first = value[0];
      const last = value[value.length - 1];

      clearTimeout(this.debounceRangeFilter);
      this.debounceRangeFilter = setTimeout(() => {
        const results = this.genCharArray(first, last);
        this.inputRangeFilterValue = {
          ...this.inputRangeFilterValue,
          [key]: results.length === this.mockRangeData[key].length
            ? []
            : results,
        };
        this.emitFilterInput();
      }, 500);
    },
    emitFilterInput(filters = {}) {
      this.$emit('input-select-filter', {
        ...this.inputSelectFilter,
        ...this.inputAttributeFilterValue,
        ...this.inputRangeFilterValue,
        ...this.inputFilterExpress,
        ...filters,
      });
    },
    validateSearchFilter() {
      this.showErrorSelectFilter = {};
      if (this.getSelectFilterRequiredFields.length === 0) {
        this.isErrorSelectFilter = false;
        return;
      }
      const getEmptyRequiredSelectFilter = this.getSelectFilterRequiredFields.filter((item) => this.inputSelectFilter[item.filterKey].length === 0);
      const getEmptyRequiredFilterKeys = getEmptyRequiredSelectFilter.map((item) => item.filterKey);
      getEmptyRequiredFilterKeys.forEach((key) => {
        this.showErrorSelectFilter = {
          ...this.showErrorSelectFilter,
          [key]: true,
        };
      });
      if (getEmptyRequiredFilterKeys.length === 0) {
        this.isErrorSelectFilter = false;
        return;
      }
      this.isErrorSelectFilter = true;
      this.$store.dispatch('dialog/setShowErrorDialog', {
        status: true,
        title: this.$t('langkey.missing-required-filter'),
        message: this.$t('langkey.set-filter'),
      });
    },
    onTableRowSelect({ item, index }) {
      this.onClickTableMore(item, index);
    },
    onTableSort({ activeSortCol, status }) {
      this.pagination = 1;
      let newStatus = '';
      if (status === 'ascending') {
        newStatus = 'ASC';
      } else if (status === 'descending') {
        newStatus = 'DESC';
      } else {
        newStatus = 'none';
      }
      this.sliceTableItems = this.sliceTableItems.slice()
        .filter((tableItem) => !tableItem.isSupplier);
      let payload = {};
      if (activeSortCol === 'purchasePrice') {
        payload = {
          activeSortCol: 'bestPrice',
          status: newStatus,
        };
      } else {
        payload = {
          activeSortCol: activeSortCol,
          status: newStatus,
        };
      }
      this.$emit('on-sort', payload);
    },
    onClickEnableButton(key, requiredKeys) {
      const emptyValues = requiredKeys.filter((key) => this.inputSelectFilter[key].length === 0);
      emptyValues.forEach((key) => {
        this.showErrorSelectFilter = {
          ...this.showErrorSelectFilter,
          [key]: true,
        };
      });
      let errorMessage = '';
      if (emptyValues.length >= 3) {
        errorMessage = this.$t('langkey.set-filter');
      } else if (key === 'aspectRatio' && emptyValues.length === 1) {
        errorMessage = this.$t('langkey.set-filter-height');
      } else if (key === 'diameter' && emptyValues.length === 2) {
        errorMessage = this.$t('langkey.set-filter-diameters');
      }
      this.$store.dispatch('dialog/setShowErrorDialog', {
        status: true,
        title: this.$t('langkey.missing-required-filter'),
        message: errorMessage,
      });
    },
    onFilterExpress() {
      if (!this.inputFilterExpressState) {
        this.inputFilterExpress = {};
      } else {
        this.inputFilterExpress = {
          expressDelivery: this.inputFilterExpressState,
        };
      }
      this.emitFilterInput();
      if (this.isStart === false) {
        this.onSearchFilter();
      }
    },
    onInputSelectFilter(selectFilterField, refKey) {
      if (selectFilterField.filterKey === 'widths') {
        this.selectFilterKey = selectFilterField.filterKey;
      } else {
        this.selectFilterKey = '';
      }
      this.setEnableSelectFilter();
      this.emitFilterInput();
      this.showErrorSelectFilter = {
        ...this.showErrorSelectFilter,
        [selectFilterField.filterKey]: false,
      };

      if (selectFilterField.single) {
        this.$refs[refKey][0].hideDropdown();
      }

      if (selectFilterField.searchOnSelect && this.isStart === false) {
        this.onSearchFilter();
      }
    },
    onFilterAttribute(attribute) {
      const isChecked = this.inputAttributeFilterState[attribute.filterValue];
      if (isChecked) {
        if (attribute.isBoolean) {
          this.inputAttributeFilterValue = {
            ...this.inputAttributeFilterValue,
            [attribute.filterKey]: true,
          };
        } else if (
          attribute.filterKey === 'seasons' ||
          attribute.filterKey === 'manufacturerClasses' ||
          attribute.filterKey === 'usages'
        ) {
          this.inputAttributeFilterValue = {
            ...this.inputAttributeFilterValue,
            [attribute.filterKey]: this.inputAttributeFilterValue[attribute.filterKey]
              ? [
                ...this.inputAttributeFilterValue[attribute.filterKey],
                attribute.filterValue,
              ]
              : [
                attribute.filterValue,
              ],
          };
        } else if (attribute.filterKey === 'vehicleTypes') {
          // set vehicleTypes
          this.attributeFilterFields.forEach((field) => {
            if (field.filterValue !== attribute.filterValue) {
              this.inputAttributeFilterState = {
                ...this.inputAttributeFilterState,
                [field.filterValue]: false,
              };
            }
          });
          this.inputAttributeFilterValue = {
            [attribute.filterKey]: [attribute.filterValue],
          };
        }
      } else {
        if (attribute.isBoolean) {
          this.inputAttributeFilterValue = {
            ...this.inputAttributeFilterValue,
            [attribute.filterKey]: false,
          };
        } else if (
          attribute.filterKey === 'seasons' ||
          attribute.filterKey === 'manufacturerClasses' ||
          attribute.filterKey === 'usages'
        ) {
          this.inputAttributeFilterValue = {
            ...this.inputAttributeFilterValue,
            [attribute.filterKey]: this.inputAttributeFilterValue[attribute.filterKey]
              .filter((val) => val !== attribute.filterValue),
          };
        } else if (attribute.filterKey === 'vehicleTypes') {
          // set vehicleTypes = car
          this.inputAttributeFilterState = {
            ...this.inputAttributeFilterState,
          };
          this.inputAttributeFilterValue = {
            ...this.inputAttributeFilterValue,
            vehicleTypes: [''],
          };
        }
      }
      this.emitFilterInput();
      setTimeout(() => {
        if (this.isStart === false) {
          if (attribute.filterKey !== 'vehicleTypes') {
            this.onSearchFilter();
          } else {
            this.onSearchFilter({
              isVehicleType: true,
            });
          }
        }
      }, 300);
    },
    onSearchFilter(options) {
      this.validateSearchFilter();
      if (this.isErrorSelectFilter) {
        return;
      }

      this.pagination = 1;
      if (options && options.isVehicleType) {
        this.$emit('search-filter', {
          ...this.inputSelectFilter,
          ...this.inputAttributeFilterValue,
          ...this.inputRangeFilterValue,
          ...this.inputFilterExpress,
          ...this.inputFilterMarketplaceAddress,
          isVehicleType: options.isVehicleType,
        });
      } else {
        this.$emit('search-filter', {
          ...this.inputSelectFilter,
          ...this.inputAttributeFilterValue,
          ...this.inputRangeFilterValue,
          ...this.inputFilterExpress,
          ...this.inputFilterMarketplaceAddress,
        });
      }
    },
    onResetFilter() {
      this.pagination = 1;
      this.setInitialInputSelectFilter();
      this.setInitialRange();
      this.setInitialAttribute();
      this.setEnableSelectFilter();
      this.inputFilterExpress = {};
      this.inputFilterExpressState = false;
      this.$emit('clear');

      if (this.tableItems.length === 0) {
        return;
      }

      setTimeout(() => {
        this.$emit('input-select-filter', {
          ...this.inputSelectFilter,
          ...this.inputAttributeFilterValue,
        });
        if (this.clearAndSearch) {
          this.onSearchFilter();
        }
      }, 300);
    },
    onResetInitFilterVehicleType() {
      this.inputAttributeFilterState = {
        car: true,
      };
      this.inputAttributeFilterValue = {
        vehicleTypes: 'car',
      };
    },
    onClickTableMore(item, index) {
      if (this.sliceTableItems[index + 1] !== undefined &&
        this.sliceTableItems[index + 1].isSupplier) {
        return;
      }
      if (item.assigns.length === 0) {
        return;
      }
      this.isLoadingAssign = true;
      this.$store.dispatch('tyre/getTyreAssigns', {
        ...item,
        ...this.inputFilterExpress,
        ...this.inputFilterMarketplaceAddress,
      })
        .then(async () => {
          const assigns = this.tyreAssignByTyreTag[item.tyreTag];
          if (assigns.length === 0) {
            this.$store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: 'Error supplier',
              message: 'Can\'t find any suppliers of the selected item',
            });
            this.isLoadingAssign = false;
            return;
          }
          const assignsWithTyreTag = this.tyreAssignByTyreTag[item.tyreTag].slice()
            .sort((a, b) => parseFloat(a.price.retailPrice) - parseFloat(b.price.retailPrice))
            .map((assignItem, index) => {
              let result = {
                ...assignItem,
                isSupplier: true,
                tyreTag: item.tyreTag,
                listPrice: item.prices['listPrice'],
                index: index,
                isLast: index === this.tyreAssignByTyreTag[item.tyreTag].length - 1,
              };
              if (item.eligibilityValue) {
                result = {
                  ...result,
                  eligibilityValue: item.eligibilityValue,
                };
              }
              return result;
            });

          if (!this.isMotorbike) {
            assignsWithTyreTag.forEach((assignItem) => {
              this.selectedTyreInSupplierQuantityState = {
                ...this.selectedTyreInSupplierQuantityState,
                [assignItem.tyreTag]: this.selectedTyreInSupplierQuantityState[assignItem.tyreTag]
                  ? {
                    ...this.selectedTyreInSupplierQuantityState[assignItem.tyreTag],
                    [assignItem.id]: assignItem.stock < 4 ? assignItem.stock : 4,
                  }
                  : {
                    [assignItem.id]: assignItem.stock < 4 ? assignItem.stock : 4,
                  },
              };
            });
          } else {
            assignsWithTyreTag.forEach((assignItem) => {
              this.selectedTyreInSupplierQuantityState = {
                ...this.selectedTyreInSupplierQuantityState,
                [assignItem.tyreTag]: this.selectedTyreInSupplierQuantityState[assignItem.tyreTag]
                  ? {
                    ...this.selectedTyreInSupplierQuantityState[assignItem.tyreTag],
                    [assignItem.id]: assignItem.stock < 2 ? assignItem.stock : 2,
                  }
                  : {
                    [assignItem.id]: assignItem.stock < 2 ? assignItem.stock : 2,
                  },
              };
            });
          }

          let localeFormat = '';
          if (this.$route.params.lang === 'en') {
            localeFormat = 'en_US';
          } else {
            localeFormat = 'de_DE';
          }

          for (const assignItem of assignsWithTyreTag) {
            if (assignItem.directDeliveryNoteDay) {
              const responseFirst = await axios.get(`${BASE_API_URL}/${localeFormat}/translation/langkey.direct_delivery.order.tooltip.${assignItem.directDeliveryNoteDay}?time=${assignItem.directDeliveryNoteCutOfTime}`);
              if (responseFirst.status === 200) {
                const responseSecond = await axios.get(`${BASE_API_URL}/${localeFormat}/translation/langkey.direct_delivery.order.tooltip?time=${assignItem.directDeliveryNoteCutOfTime}&delivery=${responseFirst.data.translation}`);
                if (responseSecond.status === 200) {
                  this.tyreSuppliersDeliveryTooptip = {
                    ...this.tyreSuppliersDeliveryTooptip,
                    [`${assignItem.tyreTag}_${assignItem.id}`]: responseSecond.data.translation,
                  };
                }
              }
            }
            this.selectedTyreInSupplierQuantityState = {
              ...this.selectedTyreInSupplierQuantityState,
              [assignItem.tyreTag]: this.selectedTyreInSupplierQuantityState[assignItem.tyreTag]
                ? {
                  ...this.selectedTyreInSupplierQuantityState[assignItem.tyreTag],
                  [assignItem.id]: assignItem.stock < 4 ? assignItem.stock : 4,
                }
                : {
                  [assignItem.id]: assignItem.stock < 4 ? assignItem.stock : 4,
                },
            };
          }

          this.sliceTableItems = insertItemInArrayAtIndex(this.sliceTableItems, index + 1, [
            {
              isSupplierHeader: true,
              isSupplier: true,
              tyreTag: item.tyreTag,
            },
            ...assignsWithTyreTag,
          ]);
          this.isLoadingAssign = false;
        })
        .catch((err) => {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error supplier',
            message: err,
          });
          this.isLoadingAssign = false;
        });
    },
    onCloseSupplier(item) {
      this.sliceTableItems = this.sliceTableItems.slice()
        .filter((tableItem) => tableItem.tyreTag !== item.tyreTag || tableItem.ean); // tableItem.ean to check it is original item
    },
    onClickSubstactQuantity(item) {
      this.selectedTyreInSupplierQuantityState = {
        ...this.selectedTyreInSupplierQuantityState,
        [item.tyreTag]: {
          ...this.selectedTyreInSupplierQuantityState[item.tyreTag],
          [item.id]: this.selectedTyreInSupplierQuantityState[item.tyreTag][item.id] - 1,
        },
      };
    },
    onChangeQuantity(e, item) {
      const max = item.stock;
      const value = Math.abs(parseInt(e.target.value, 10)) || 0;
      if (max !== null && value > max) {
        this.selectedTyreInSupplierQuantityState = {
          ...this.selectedTyreInSupplierQuantityState,
          [item.tyreTag]: {
            ...this.selectedTyreInSupplierQuantityState[item.tyreTag],
            [item.id]: max,
          },
        };
        return;
      }
      if (value === 0) {
        this.selectedTyreInSupplierQuantityState = {
          ...this.selectedTyreInSupplierQuantityState,
          [item.tyreTag]: {
            ...this.selectedTyreInSupplierQuantityState[item.tyreTag],
            [item.id]: 1,
          },
        };
        return;
      }
      this.selectedTyreInSupplierQuantityState = {
        ...this.selectedTyreInSupplierQuantityState,
        [item.tyreTag]: {
          ...this.selectedTyreInSupplierQuantityState[item.tyreTag],
          [item.id]: value,
        },
      };
    },
    onClickAddQuantity(item) {
      this.selectedTyreInSupplierQuantityState = {
        ...this.selectedTyreInSupplierQuantityState,
        [item.tyreTag]: {
          ...this.selectedTyreInSupplierQuantityState[item.tyreTag],
          [item.id]: this.selectedTyreInSupplierQuantityState[item.tyreTag][item.id] + 1,
        },
      };
    },
    async onClickAddToCart(item) {
      const userType = sessionStorage.getItem('userType');
      if (userType && userType.toString().toLowerCase().includes('test')) {
        if (this.isRegistered) {
          this.registeredDialog = true;
        } else {
          this.$router.push(this.$i18nRoute({
            name: 'register-full',
          }));
        }
      } else {
        if (!this.isConfiguratorTyre) {
          this.selectedAddToCartItem = item;
          this.showAddToCartDialog = true;
        } else {
          try {
            this.selectedItems.displayCartItems = [];

            const tyreItem = this.filteredSliceTableItems.find((tableItem) => {
              if (tableItem.assigns) {
                return tableItem.assigns.find((assign) => assign == item.id);
              }
            });

            this.selectedItems.tyre = {
              assignId: item.id,
              quantity: this.selectedTyreInSupplierQuantityState[item.tyreTag][item.id],
              cartItems: [{
                ...item,
                description: tyreItem.description,
                imageTemplate: tyreItem.imageTemplate,
              }],
            };
            await this.$store.dispatch('rimconfigCarConfigurator/selectConfiguratorTyre', this.selectedItems.tyre);
            this.selectedItems.wheel.cartItems.forEach((wheelItem) => {
              this.selectedItems.displayCartItems = [
                ...this.selectedItems.displayCartItems,
                {
                  ...wheelItem,
                  isWheel: true,
                }];
            });
            if (
              this.selectedItems.tpms &&
              this.selectedItems.tpms.quantity &&
              this.selectedItems.tpms.assignId &&
              this.selectedItems.tpms.cartItems.length
            ) {
              this.selectedItems.tpms.cartItems.forEach((tpmsItem) => {
                this.selectedItems.displayCartItems = [
                  ...this.selectedItems.displayCartItems,
                  {
                    ...tpmsItem,
                    isTpms: true,
                  }];
              });
            }
            this.selectedItems.displayCartItems = [
              ...this.selectedItems.displayCartItems,
              {
                ...item,
                description: tyreItem.description,
                imageTemplate: tyreItem.imageTemplate,
                isTyre: true,
              }];

            //display another dialog
            this.showCartDialogConfiguratorTyre = true;
          } catch (err) {
            console.log(err);
          }
        }
      }
    },
    async onShowCartConfiguratorTyre() {
      this.showCartDialogConfiguratorTyre = false;
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      const userType = sessionStorage.getItem('userType');
      if (userType && userType.toString().toLowerCase().includes('test')) {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
        this.$router.push(this.$i18nRoute({
          name: 'register-full',
        }));
        return;
      }
      try {
        // add tyre to cart
        const expressShipmentAvailable = this.selectedItems.tyre.cartItems[0].expressShipmentAvailable;
        if (expressShipmentAvailable) {
          await this.$store.dispatch('cart/addCartItem', {
            id: this.selectedItems.tyre.assignId,
            quantity: this.selectedItems.tyre.quantity,
            context: {
              deliveryType: 'direct_delivery',
            },
          });
        } else {
          await this.$store.dispatch('cart/addCartItem', {
            id: this.selectedItems.tyre.assignId,
            quantity: this.selectedItems.tyre.quantity,
          });
        }
        // add tpms add to cart
        if (
          this.selectedItems.tpms &&
          this.selectedItems.tpms.quantity &&
          this.selectedItems.tpms.assignId &&
          this.selectedItems.tpms.cartItems.length
        ) {
          await this.$store.dispatch('cart/addCartItem', {
            id: this.selectedItems.tpms.assignId,
            quantity: this.selectedItems.tpms.quantity,
          });
        }
        // add wheel to cart
        await this.$store.dispatch('cart/addCartItem', {
          id: this.selectedItems.wheel.assignIds[0],
          quantity: this.selectedItems.wheel.quantity,
          context: {
            kitMan: this.selectedItems.wheel.cartItems[0].kitMan || 'Serie',
            carId: this.$route.params.carTag,
          },
        });
        if (this.selectedItems.wheel.isCombination === true) {
          await this.$store.dispatch('cart/addCartItem', {
            id: this.selectedItems.wheel.assignIds[0],
            quantity: this.selectedItems.wheel.quantity,
            context: {
              kitMan: this.selectedItems.wheel.cartItems[1].kitMan || 'Serie',
              carId: this.$route.params.carTag,
            },
          });
        }
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
        this.$router.push(this.$i18nRoute({
          name: 'cart',
        }));
      } catch (err) {
        console.log(err);
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      }
    },
    async onContinueShoppingConfiguratorTyre() {
      this.showCartDialogConfiguratorTyre = false;
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      try {
        // add tyre to cart
        const expressShipmentAvailable = this.selectedItems.tyre.cartItems[0].expressShipmentAvailable;
        if (expressShipmentAvailable) {
          await this.$store.dispatch('cart/addCartItem', {
            id: this.selectedItems.tyre.assignId,
            quantity: this.selectedItems.tyre.quantity,
            context: {
              deliveryType: 'direct_delivery',
            },
          });
        } else {
          await this.$store.dispatch('cart/addCartItem', {
            id: this.selectedItems.tyre.assignId,
            quantity: this.selectedItems.tyre.quantity,
          });
        }
        // add tpms add to cart
        if (
          this.selectedItems.tpms &&
          this.selectedItems.tpms.quantity &&
          this.selectedItems.tpms.assignId &&
          this.selectedItems.tpms.cartItems.length
        ) {
          await this.$store.dispatch('cart/addCartItem', {
            id: this.selectedItems.tpms.assignId,
            quantity: this.selectedItems.tpms.quantity,
          });
        }
        // add wheel to cart
        await this.$store.dispatch('cart/addCartItem', {
          id: this.selectedItems.wheel.assignIds[0],
          quantity: this.selectedItems.wheel.quantity,
          context: {
            kitMan: this.selectedItems.wheel.cartItems[0].kitMan || 'Serie',
            carId: this.$route.params.carTag,
          },
        });
        if (this.selectedItems.wheel.isCombination === true) {
          await this.$store.dispatch('cart/addCartItem', {
            id: this.selectedItems.wheel.assignIds[0],
            quantity: this.selectedItems.wheel.quantity,
            context: {
              kitMan: this.selectedItems.wheel.cartItems[1].kitMan || 'Serie',
              carId: this.$route.params.carTag,
            },
          });
        }
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      } catch (err) {
        console.log(err);
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      }
    },
    getWheelImageBySize(image) {
      if (image.includes('https://cdn.jfnet.de/tpms')) {
        return getImageBySize(image, 120, 120);
      }

      return getImageBySize(image, 120, 120);
    },
    getTpmsImageBySize(image) {
      if (image.includes('https://cdn.jfnet.de/tpms')) {
        return getImageBySize(image, 120, 120);
      }

      return getImageBySize(image, 120, 120).replace('https://cdn.jfnet.de/', 'https://cdn.jfnet.de/tpms/');
    },
    getTyreImageBySize(image, width, height) {
      let result = image;
      result = result.replace('{width}', width);
      result = result.replace('{height}', height);
      result = result.replace('{orientation}', 'cc');
      if (image.includes('{bgColor}')) {
        result = result.replace('{bgColor}', 'xxxxxx');
      } else if (image.includes('{backgroundColor}')) {
        result = result.replace('{backgroundColor}', 'xxxxxx');
      }
      if (image.includes('{extension}')) {
        result = result.replace('{extension}', 'png');
      } else if (image.includes('{fileExtension}')) {
        result = result.replace('{fileExtension}', 'png');
      }
      return result;
    },
    onSubtractQuantitySelectedWheel() {
      let quantity = this.selectedItems.wheel.quantity;
      if (quantity > 0) {
        this.selectedItems.wheel.quantity = quantity - 1;
        this.$forceUpdate();
      }
    },
    onAddQuantitySelectedWheel() {
      let quantity = this.selectedItems.wheel.quantity;
      this.selectedItems.wheel.quantity = quantity + 1;
      this.$forceUpdate();
    },
    onUpdateQuantitySelectedWheel (event, index) {
      let newQuantity;
      let previousQuantity;
      previousQuantity = this.selectedItems.wheel.quantity || 0;

      if (!event.target.value) {
        this.selectedItems.wheel.quantity = previousQuantity;
        return;
      }

      const max = this.selectedItems.wheel.cartItems[index].stock;
      if (event.target.value > max) {
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: this.$t('langkey.stock-limit'),
          message: `${this.$t('langkey.stock-limit-at')} ${max}`,
        });
        this.selectedItems.wheel.quantity = max;
        newQuantity = max;
      } else if (event.target.value < 0) {
        this.selectedItems.wheel.quantity = 0;
        newQuantity = 0;
      } else {
        newQuantity = parseInt(event.target.value);
      }
      this.selectedItems.wheel.quantity = newQuantity;
    },
    onSubtractQuantitySelectedTpms() {
      let quantity = this.selectedItems.tpms.quantity;
      if (quantity > 0) {
        this.selectedItems.tpms.quantity = quantity - 1;
        this.$forceUpdate();
      }
    },
    onAddQuantitySelectedTpms() {
      let quantity = this.selectedItems.tpms.quantity;
      this.selectedItems.tpms.quantity = quantity + 1;
      this.$forceUpdate();
    },
    onUpdateQuantitySelectedTpms (event, index) {
      let newQuantity;
      let previousQuantity;
      previousQuantity = this.selectedItems.tpms.quantity || 0;

      if (!event.target.value) {
        this.selectedItems.tpms.quantity = previousQuantity;
        return;
      }

      const max = this.selectedItems.tpms.cartItems[index].stock;
      if (event.target.value > max) {
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: this.$t('langkey.stock-limit'),
          message: `${this.$t('langkey.stock-limit-at')} ${max}`,
        });
        this.selectedItems.tpms.quantity = max;
        newQuantity = max;
      } else if (event.target.value < 0) {
        this.selectedItems.tpms.quantity = 0;
        newQuantity = 0;
      } else {
        newQuantity = parseInt(event.target.value);
      }
      this.selectedItems.tpms.quantity = newQuantity;
    },
    onSubtractQuantitySelectedTyre() {
      let quantity = this.selectedItems.tyre.quantity;
      if (quantity > 0) {
        this.selectedItems.tyre.quantity = quantity - 1;
        this.$forceUpdate();
      }
    },
    onAddQuantitySelectedTyre() {
      let quantity = this.selectedItems.tyre.quantity;
      this.selectedItems.tyre.quantity = quantity + 1;
      this.$forceUpdate();
    },
    onUpdateQuantitySelectedTyre (event, index) {
      let newQuantity;
      let previousQuantity;
      previousQuantity = this.selectedItems.tyre.quantity || 0;

      if (!event.target.value) {
        this.selectedItems.tyre.quantity = previousQuantity;
        return;
      }

      const max = this.selectedItems.tyre.cartItems[index].stock;
      if (event.target.value > max) {
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: this.$t('langkey.stock-limit'),
          message: `${this.$t('langkey.stock-limit-at')} ${max}`,
        });
        this.selectedItems.tyre.quantity = max;
        newQuantity = max;
      } else if (event.target.value < 0) {
        this.selectedItems.tyre.quantity = 0;
        newQuantity = 0;
      } else {
        newQuantity = parseInt(event.target.value);
      }
      this.selectedItems.tyre.quantity = newQuantity;
    },
    async onContinueShopping() {
      this.showAddToCartDialog = false;
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      const item = this.selectedAddToCartItem;
      if (this.inputFilterExpressState) {
        try {
          await this.$store.dispatch('cart/addCartItem', {
            id: item.id,
            quantity: this.selectedTyreInSupplierQuantityState[item.tyreTag][item.id],
            context: {
              deliveryType: 'direct_delivery',
            },
          });
          if (!this.selectedAddress.default) {
            // if differing delivery address is chosen (not default address)
            await this.$store.dispatch('cart/getCartItems');
            const getSourceId = this.getCartItems.find((cartItem) => cartItem.sourceName === item.supplier).sourceId;
            await this.$store.dispatch('cart/checkAllowChangeAddressForSource', {
              sourceId: getSourceId,
            });
            if (this.allowChangeAddressForSource) {
              await CartService.changeAddress({
                sourceId: getSourceId,
                addressId: this.selectedAddress.id,
              });
            } else {
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.differing_delivery_address_unavailable_header'),
                message: this.$t('langkey.differing_delivery_address_unavailable_message'),
              });
              // set selected address back to initial address
              const defaultAddress  = this.userAddresses.find((address) => address.default);
              if (defaultAddress) {
                this.selectedAddressOption = [`${defaultAddress.name} ${defaultAddress.street}, ${defaultAddress.zip} ${defaultAddress.city}, ${defaultAddress.countryCode}`];
              }
              this.$forceUpdate();
            }
          }
        } catch (err) {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error Add item to cart',
            message: err,
          });
        } finally {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        }
      } else {
        let payload = {
          id: item.id,
          quantity: this.selectedTyreInSupplierQuantityState[item.tyreTag][item.id],
        };
        if (item.directDeliveryNoteDay === 'today' || item.directDeliveryNoteDay === 'tomorrow') {
          payload = {
            ...payload,
            context: {
              deliveryType: 'direct_delivery',
            },
          };
        }
        try {
          await this.$store.dispatch('cart/addCartItem', payload);
          if (!this.selectedAddress.default) {
            // if differing delivery address is chosen (not default address)
            await this.$store.dispatch('cart/getCartItems');
            const getSourceId = this.getCartItems.find((cartItem) => cartItem.sourceName === item.supplier).sourceId;
            await this.$store.dispatch('cart/checkAllowChangeAddressForSource', {
              sourceId: getSourceId,
            });
            if (this.allowChangeAddressForSource) {
              await CartService.changeAddress({
                sourceId: getSourceId,
                addressId: this.selectedAddress.id,
              });
            } else {
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.differing_delivery_address_unavailable_header'),
                message: this.$t('langkey.differing_delivery_address_unavailable_message'),
              });
              // set selected address back to initial address
              const defaultAddress  = this.userAddresses.find((address) => address.default);
              if (defaultAddress) {
                this.selectedAddressOption = [`${defaultAddress.name} ${defaultAddress.street}, ${defaultAddress.zip} ${defaultAddress.city}, ${defaultAddress.countryCode}`];
              }
              this.$forceUpdate();
            }
          }
        } catch (err) {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error Add item to cart',
            message: err,
          });
        } finally {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        }
      }
    },
    async onShowCart() {
      this.showAddToCartDialog = false;
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      const item = this.selectedAddToCartItem;
      if (this.inputFilterExpressState) {
        try {
          await this.$store.dispatch('cart/addCartItem', {
            id: item.id,
            quantity: this.selectedTyreInSupplierQuantityState[item.tyreTag][item.id],
            context: {
              deliveryType: 'direct_delivery',
            },
          });
          if (!this.selectedAddress.default) {
            // if differing delivery address is chosen (not default address)
            await this.$store.dispatch('cart/getCartItems');
            const getSourceId = this.getCartItems.find((cartItem) => cartItem.sourceName === item.supplier).sourceId;
            await this.$store.dispatch('cart/checkAllowChangeAddressForSource', {
              sourceId: getSourceId,
            });
            if (this.allowChangeAddressForSource) {
              await CartService.changeAddress({
                sourceId: getSourceId,
                addressId: this.selectedAddress.id,
              });
            } else {
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.differing_delivery_address_unavailable_header'),
                message: this.$t('langkey.differing_delivery_address_unavailable_message'),
              });
            }
          }
          this.$router.push(
            this.$i18nRoute({
              name: 'cart',
            }),
          );
        } catch (err) {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error Add item to cart',
            message: err,
          });
        } finally {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        }
      } else {
        let payload = {
          id: item.id,
          quantity: this.selectedTyreInSupplierQuantityState[item.tyreTag][item.id],
        };
        if (item.directDeliveryNoteDay === 'today' || item.directDeliveryNoteDay === 'tomorrow') {
          payload = {
            ...payload,
            context: {
              deliveryType: 'direct_delivery',
            },
          };
        }
        try {
          await this.$store.dispatch('cart/addCartItem', payload);
          if (!this.selectedAddress.default) {
            // if differing delivery address is chosen (not default address)
            await this.$store.dispatch('cart/getCartItems');
            const getSourceId = this.getCartItems.find((cartItem) => cartItem.sourceName === item.supplier).sourceId;
            await this.$store.dispatch('cart/checkAllowChangeAddressForSource', {
              sourceId: getSourceId,
            });
            if (this.allowChangeAddressForSource) {
              await CartService.changeAddress({
                sourceId: getSourceId,
                addressId: this.selectedAddress.id,
              });
            } else {
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.differing_delivery_address_unavailable_header'),
                message: this.$t('langkey.differing_delivery_address_unavailable_message'),
              });
            }
          }
          this.$router.push(
            this.$i18nRoute({
              name: 'cart',
            }),
          );
        } catch (err) {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error Add item to cart',
            message: err,
          });
        } finally {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        }
      }
    },
    async onClickInfo(event, tyreItem) {
      event.stopPropagation();
      this.tyreDetailDialogItem = tyreItem;
      if (this.isMotorbike) {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: true,
        });
        try {
          const response = await TyreService.getTyreImage({
            eans: this.tyreDetailDialogItem.ean,
          });
          if (response.status === 200) {
            this.tyreImageItem = response.data[this.tyreDetailDialogItem.ean];
          }
        } finally {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          setTimeout(() => {
            this.$refs.tyreSearchDetailDialogRef.show();
          }, 200);
        }
        return;
      }
      setTimeout(() => {
        this.$refs.tyreSearchDetailDialogRef.show();
      }, 200);
    },
    getSelectPlaceholder(key) {
      return this.selectFilterFields.find((item) => {
        return item.key === key;
      }).placeholder;
    },
    genCharArray(charA, charZ) {
      let a = [], i = charA.charCodeAt(0), j = charZ.charCodeAt(0);
      for (; i <= j; ++i) {
        a.push(String.fromCharCode(i));
      }
      return a;
    },
    handleTableScroll() {
      const offsetHeight = this.tableScrollEl.offsetHeight;
      const scrollHeight = this.tableScrollEl.scrollHeight;
      const scrollYPos = this.tableScrollEl.scrollTop;
      if ((scrollYPos + offsetHeight === scrollHeight)
        && this.isLoadingPagination === false
        && this.isNoMorePaginationResult === false) {
        this.sliceTableItems = [
          ...this.sliceTableItems,
          { isLoading: true },
        ];
        this.pagination += 1;
        this.$emit('next-page', this.pagination);
      }
    },
    hasSelectInputFilter(requiredKeys) {
      return requiredKeys.every((key) => this.inputSelectFilter[key].length > 0);
    },
    isInitialRangeFilter(rangeFilterKey) {
      const inputRange = this.inputRangeFilterValue[rangeFilterKey];
      if (inputRange === undefined) {
        return false;
      }
      if (inputRange.length === 0) {
        return false;
      }
      return true;
    },
    getUserAddresses() {
      this.$store.dispatch('user/checkAllowAddress')
        .then(() => {
          if (this.isAllowAddressUser) {
            this.isLoadingDeliveryAddresses = true;
            this.$store.dispatch('user/getUserAdresses')
              .then(() => {
                const defaultAddress  = this.userAddresses.find((address) => address.default);
                if (defaultAddress) {
                  this.selectedAddressOption = [`${defaultAddress.name} ${defaultAddress.street}, ${defaultAddress.zip} ${defaultAddress.city}, ${defaultAddress.countryCode}`];
                }
              })
              .finally(() => {
                this.isLoadingDeliveryAddresses = false;
              });
          }
        });
    },
    onSelectAddress() {
      if (this.selectedAddress) {
        if (!this.selectedAddress.default) {
          this.inputFilterMarketplaceAddress = {
            marketplace_address: this.selectedAddress.id,
          };
        } else {
          this.inputFilterMarketplaceAddress = {};
        }
      } else {
        // no address selected
        this.inputFilterMarketplaceAddress = {};
      }
      this.onSearchFilter();
      this.$refs.selectDropdownDeliveryAddress.hideDropdown();
    },
    createNewAddress() {
      this.inputAddressData = {
        name: {
          required: true,
          value: '',
        },
        street: {
          required: true,
          value: '',
        },
        streetNumber: {
          required: true,
          value: '',
        },
        zip: {
          required: true,
          value: '',
        },
        city: {
          required: true,
          value: '',
        },
        countryCode: {
          required: false,
          value: 'DE',
        },
        defaultValue: {
          required: false,
          value: false,
        },
      };
      this.createNewAddressDialog = true;
    },
    onSaveAddress() {
      this.validateInput();
      if (this.hasErrorInputAddress) {
        return;
      }
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      let payload = {};
      Object.keys(this.inputAddressData).forEach((key) => {
        payload = {
          ...payload,
          [key]: this.inputAddressData[key].value,
        };
      });
      this.$store.dispatch('user/addUserAddress', payload)
        .then(() => {
          this.createNewAddressDialog = false;
          const defaultAddress  = this.userAddresses.find((address) => address.default);
          if (defaultAddress) {
            this.selectedAddressOption = [`${defaultAddress.name} ${defaultAddress.street}, ${defaultAddress.zip} ${defaultAddress.city}, ${defaultAddress.countryCode}`];
          }
        })
        .finally(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
    },
    validateInput () {
      Object.keys(this.inputAddressData).forEach((key) => {
        if (this.inputAddressData[key].required && !this.inputAddressData[key].value) {
          this.errorMessageInputAddressData = {
            ...this.errorMessageInputAddressData,
            [key]: this.$t('langkey.mandatory-entry-fields'),
          };
        }
      });
    },
    toggleDefaultValue() {
      this.inputAddressData.defaultValue.value = !this.inputAddressData.defaultValue.value;
    },
    toTyreOffer(item) {
      this.$router.push(this.$i18nRoute({
        name: 'tyre-offer',
        params: {
          // tyreTag: item.tyreTag,
          eans: item.ean,
        },
      }));
    },
    setTyreSizeFilter(filter) {
      // Set tyre size filter later if initial filter was not set in setInitialFilterFromSearch
      if (
        (
          this.inputSelectFilter.widths.length === 0 ||
          this.inputSelectFilter.aspectRatios.length === 0 ||
          this.inputSelectFilter.diameters.length === 0
        )
        || (
          this.inputSelectFilter.widths[0] !== filter.widths[0] ||
          this.inputSelectFilter.aspectRatios[0] !== filter.aspectRatios[0] ||
          this.inputSelectFilter.diameters[0] !== filter.diameters[0]
        )
      ) {
        this.inputSelectFilter = {
          ...this.inputSelectFilter,
          widths: filter.widths,
          aspectRatios: filter.aspectRatios,
          diameters: filter.diameters,
        };
        this.setEnableSelectFilter();
        this.emitFilterInput({
          initialSearch: true,
        });
        this.inputFilterExpressState = false;
      }
    },
  },
};
</script>
